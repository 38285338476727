import React, {useState, useEffect} from 'react'

import Fade from 'react-reveal';

import {Card, Col, Container, Row} from "react-bootstrap";

import logo from './logo.png';
import './App.scss';
import {BrandLogo} from "./styles";
import ContactForm from "./ContactForm";
import moment from "moment";

function App() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  return <Fade top when={show} duration={700}>
    <Container style={{marginTop: "calc(50vh - 450px)"}}>
      <Row className="justify-content-md-center">
        <Col md="6">
          <div className="text-center">
            <BrandLogo src={logo} alt="CRM Conception" />
          </div>
          <div className="text-center">
            Notre site est en cours de développement et sera disponible très bientôt
          </div>
          <Card className="text-center shadow mt-5">
            <Card.Header>Vous avez un projet de développement ? Contactez-nous !</Card.Header>
            <Card.Body>
              <ContactForm />
            </Card.Body>
            <Card.Footer className="text-muted font-size-10px">{`${moment().format('YYYY')} | CRM Conception | Tous droits réservés`}</Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fade>
}

export default App;
