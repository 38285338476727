import React, {useState, useRef, Fragment} from 'react'
import {Alert, Button, Form, Spinner} from "react-bootstrap";
import emailjs from '@emailjs/browser';
import Shake from 'react-reveal/Shake'

const ContactForm = () => {
    const form = useRef()

    const [validated, setValidated] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const [fetching, setFetching] = useState({
        loading: false,
        success: false,
        error: false,
    })

    const handleSubmit = (e) => {
        const form = e.currentTarget
        e.preventDefault()
        setValidated(true)
        form.checkValidity() && submit()
    }

    const submit = () => {
        setFetching({
            loading: true,
            error: false
        })

        emailjs.sendForm('service_9tgakg9', 'template_rp3tp9h', form.current, 'user_1iFPoCNIO5bLS1oASx5Es')
            .then((result) => {
                setFetching({
                    loading: false,
                    error: false,
                    success: true
                })
            }, (error) => {
                setFetching({
                    loading: false,
                    error: true,
                    success: false
                })
            })
    }

    return <Fragment>
        {
            fetching.error &&
            <Shake duration={500}>
                <Alert variant="danger">
                    Une erreur est survenue pendant l'envoi de votre message
                </Alert>
            </Shake>
        }
        {
            fetching.success &&
            <Alert variant="success" className="mb-0">
                Votre message nous est bien parvenu. Une réponse vous sera apportée rapidement.
            </Alert>
        }
        {
            !fetching.success &&
            <Form ref={form} noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Control type="text"
                                  placeholder="Votre nom"
                                  name="name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  required
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control type="email"
                                  placeholder="Votre email"
                                  name="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control as="textarea"
                                  rows={5}
                                  placeholder="Votre message"
                                  name="message"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  style={{resize: "none"}}
                                  required
                    />
                </Form.Group>
                <Button className="d-block w-100" variant="primary" type="submit" disabled={fetching.loading}>
                    Envoyer votre message
                    {
                        fetching.loading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-2"
                        />
                    }
                </Button>
            </Form>
        }
    </Fragment>
}

export default ContactForm
